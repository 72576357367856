:global(#app) {
  .button {
    box-shadow: 0 1px 0 #cbcccc;
  }
  .row {
    border:1px solid #8d8d8d;
  }
  .row > td {
    border:1px solid #d3d3d3;
    padding:3px 5px;
  }
}
