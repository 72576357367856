:global(#app) {
    .emptyList {
        display:block;
        text-align:center;
        font-size:18px;
        font-weight: 500;
        padding-top:50px;
        padding-bottom:50px;
        color:rgb(48, 47, 47);
    }
    .archiveTable {
       border-collapse: collapse;
    }
    .archiveTable > thead > tr > th {
        padding:5px;
        background-color: #efefef;
        border:1px solid #d3d3d3;
    }
  }
  